"use client";
import { Button } from "@/components/ui/button";
import currency from "@/functions/currency";
import { ViewContentPixelFB } from "@/functions/pixelEvents";
import { useHitungDiskon } from "@/lib/utils";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";

export default function CardDescription({ a }: { a: DataProduct }) {
  const router = useRouter();
  const harga = {
    max: a?.price_max,
    min: a?.price_min
  };
  let diskonRange = {
    status: a?.diskon_product > 0,
    total: a?.diskon_product
  };

  // validasi max total diskon persen
  diskonRange.total = diskonRange.total >= 70 ? 70 : diskonRange.total;

  // diskon_product = sudah dijumlah di backend diskon product + diskon brand
  const diskonSale = {
    status: a?.diskon_product_nominal > 0,
    total: a?.diskon_product_nominal
  };

  let checkUseDiskon = useHitungDiskon(a, harga?.max);

  useEffect(() => {
    const section = document.getElementById(
      `${a?.name_product?.replaceAll(" ", "-")}-${a?.product_id}`
    );
    section?.addEventListener("click", () => {
      ViewContentPixelFB({
        content_name: `Product Detail - ${a.name_product}`,
        content_category: a.brand,
        content_ids: a.product_id,
        value: checkUseDiskon,
        product_catalog_id: a.product_id,
        currency: 'IDR'
      });
    });
  }, [a]);

  return (
    <div className="text-greyscaleGON-700">
      <p className="font-poppins @md:text-2xl text-xl font-semibold ">
        {a.name_product}
      </p>
      <div className="flex flex-col gap-1 border-b pb-2">
        <p className="font-poppins @md:text-xl text-base">
          {currency(checkUseDiskon)}
        </p>
        {(diskonRange?.status || diskonSale?.status) && (
          <div className={clsx("flex gap-2 pb-2", "flex-col")}>
            <span className="@md:text-base text-sm text-greyscaleGON-400 max-w-full whitespace-nowrap overflow-hidden text-ellipsis line-through">
              {currency(harga?.max)}
            </span>
            <div
              className={clsx(
                `w-fit py-1 bg-secondaryGON-50 px-2 rounded-full `
              )}
            >
              <p className="text-xs text-secondaryGON-400">
                Disc {diskonRange?.total}%
              </p>
            </div>
          </div>
        )}
      </div>
      <Button
        variant={"link"}
        className="p-0 m-0"
        onClick={() => router.push(`/search/${a.brand}`)}
        aria-label={`${a?.name_product}-${a.brand}`}
      >
        <p className="font-poppins @md:text-base text-sm">{a.brand}</p>
      </Button>
    </div>
  );
}

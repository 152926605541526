"use client";
import { useEffect } from "react";

export async function usePageViewPixelFB() {
  useEffect(() => {
    const hasPageViewBeenCountedToday = () => {
      const lastPageViewDate = localStorage.getItem("lastPageViewDate_pixelFB");
      const today = new Date().toDateString();

      return lastPageViewDate === today;
    };

    const setPageViewForToday = () => {
      const today = new Date().toDateString();
      localStorage.setItem("lastPageViewDate_pixelFB", today);
    };

    (async () => {
      if (!hasPageViewBeenCountedToday()) {        
        if (!process.env.FACEBOOK_PIXEL_ID) return null;

        const { default: ReactPixel } = await import("react-facebook-pixel");
        ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
        ReactPixel.pageView();
        setPageViewForToday();
      }
    })();
  }, []);
}

export const ChatCSPixelFB = async (data: {
  content_name: string; // "title";
  content_category: string; // "desc";
  content_ids: string; // '+62xxxxxxxxx';
}) => {
  if (!process.env.FACEBOOK_PIXEL_ID) return null;

  const { default: ReactPixel } = await import("react-facebook-pixel");
  ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
  ReactPixel.track("Contact", data);
};

export const ViewContentPixelFB = async (data: {
  content_name: string; // "Really Fast Running Shoes";
  content_category: string; // "Apparel & Accessories > Shoes";
  content_ids: string; // a;
  content_type?: string | "product";
  value: number; // 0.5;
  currency?: string | 'IDR'; // "IDR";
  product_catalog_id: string;
}) => {
  if (!process.env.FACEBOOK_PIXEL_ID) return null;

  const { default: ReactPixel } = await import("react-facebook-pixel");
  ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
  ReactPixel.track("ViewContent", data);
};

export const AddToCartPixelFB = async (data: {
  content_name: string; // "Really Fast Running Shoes";
  content_category: string; // "Apparel & Accessories > Shoes";
  content_ids: string; // a;
  content_type?: string | "product";
  product_catalog_id: string;
  value: number; // 0.5;
  currency?: string | "IDR"; // "IDR";
}) => {
  if (!process.env.FACEBOOK_PIXEL_ID) return null;

  const { default: ReactPixel } = await import("react-facebook-pixel");
  ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
  ReactPixel.track("AddToCart", data);
};

export const CheckoutPixelFB = async (data: {
  content_name: string; // "Really Fast Running Shoes";
  content_ids: string[]; // [a,b,c];
  content_type?: string | "product";
  value: number; // 0.5;
  currency?: string; // "IDR";
}) => {
  if (!process.env.FACEBOOK_PIXEL_ID) return null;

  const { default: ReactPixel } = await import("react-facebook-pixel");
  ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
  ReactPixel.track("InitiateCheckout", data);
};

import currency from "@/functions/currency";
import { type ClassValue, clsx } from "clsx";
import { ReadonlyURLSearchParams } from "next/navigation";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const createUrl = (
  pathname: string,
  params: URLSearchParams | ReadonlyURLSearchParams
) => {
  const paramsString = params.toString();
  const queryString = `${paramsString?.length ? "?" : ""}${paramsString}`;

  return `${pathname}${queryString}`;
};

export function useHitungDiskon(datas: Product | Diskon, price: any) {
  // diskon_product = sudah dijumlah di backend diskon product + diskon brand
  
  let diskonPersentase = {
    status: datas?.diskon_product > 0,
    total: datas?.diskon_product
  };

  // validasi max total diskon persen
  diskonPersentase.total =
    diskonPersentase.total >= 70 ? 70 : diskonPersentase.total;

  const total_harga_persentase = Math.round(
    parseFloat(
      !diskonPersentase?.status
        ? price
        : ((100 - diskonPersentase?.total) / 100) * price
    )
  );

  const diskonRupiah = {
    status: datas?.diskon_product_nominal > 0,
    total: datas?.diskon_product_nominal
  };

  const total_harga_rupiah = Math.round(
    parseFloat(
      !diskonRupiah?.status
        ? price
        : price - diskonRupiah?.total > 0
          ? price - diskonRupiah?.total
          : 0
    )
  );

  return diskonPersentase?.status ? total_harga_persentase : total_harga_rupiah;
}

export function checkSomeHarga(min: number, max: number) {
  return min === max ? currency(max) : `${currency(min)} - ${currency(max)}`;
}

export function useVariantsOption(variants: any[]) {
  return variants.reduce((prev, curr) => {
    return {
      ...prev,
      size_values: {
        ...(prev.size_values ?? {}),
        [curr.variant1_size]: [
          ...(prev.size_values?.[curr.variant1_size] ?? []),
          {
            ...curr,
            sku: curr.sku,
            color_values: curr.variant2_color,
            stock: curr.stock,
            variant_id: curr.variant_id,
            thumbnails: curr.thumbnails,
            harga: curr.price_tags
          }
        ]
      },
      color_values: {
        ...(prev.color_values ?? {}),
        [curr.variant2_color]: [
          ...(prev.color_values?.[curr.variant2_color] ?? []),
          {
            ...curr,
            sku: curr.sku,
            size_values: curr.variant1_size,
            stock: curr.stock,
            variant_id: curr.variant_id,
            thumbnails: curr.thumbnails,
            harga: curr.price_tags
          }
        ]
      }
    };
  }, {});
}

export function generateRandomString(length: number) {
  if (!length) {
    length = 5;
  }
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let randomString = "";
  const charactersLength = characters?.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

export function getShippingWeight(weightGrams: number) {
  if (weightGrams < 310) return weightGrams;
  // Extract the number of kilograms and remaining grams
  const kilograms = Math.floor(weightGrams / 1000);
  const remainingGrams = weightGrams % 1000;

  // Check if the remaining grams are 31 or more
  if (remainingGrams >= 310) {
    return (kilograms + 1) * 1000;
  }

  // If not, return the original weight rounded to the nearest kilogram
  return kilograms * 1000;
}

export function validasiNameShipping(item: OngkirFieldValue) {
  const {
    serviceName,
    nameService,
    service_display,
    service,
    rate_name,
    etd,
    etd_from,
    etd_thru,
    rate_id
  } = item || {};

  const checkEtd =
    etd_from == null
      ? etd_thru
      : etd_thru == null
        ? etd_from
        : etd_from == etd_thru
          ? etd_thru
          : `${etd_from}-${etd_thru}`;
  const name =
    item?.provider?.toLowerCase() == "rajaongkir"
      ? rate_id
      : serviceName || nameService || service_display || service || rate_name;
  let date =
    (etd == "" || etd == null ? "" : `(${etd})`) ||
    (checkEtd == null ? "" : `(${checkEtd} hari)`);

  if (item?.provider?.toLowerCase() == "wahana") {
    date = `(${
      Number(item?.price?.leadTime) > 0
        ? `1 - ${item?.price?.leadTime}`
        : item?.price?.leadTime
    } hari)`;
  }

  return {
    name,
    date,
    field:
      item?.provider?.toLowerCase() == "rajaongkir"
        ? "rate_id"
        : serviceName
          ? "serviceName"
          : nameService
            ? "nameService"
            : service_display
              ? "service_display"
              : rate_name
                ? "rate_name"
                : "service"
  };
}

export function fromCurrencyToNumber(val: string) {
  // Use regex to extract the number
  const cleanedValue = val.replace(/[^\d]/g, ""); // Remove non-digit characters
  const integerPart = parseInt(cleanedValue, 10);

  return integerPart;
}

export function isSameURL(target: URL, current: URL) {
  const cleanTarget =
    target.protocol + "//" + target.host + target.pathname + target.search;
  const cleanCurrent =
    current.protocol + "//" + current.host + current.pathname + current.search;

  return cleanTarget === cleanCurrent;
}

export function isSameURLWithoutSearch(target: URL, current: URL) {
  const cleanTarget = target.protocol + "//" + target.host + target.pathname;
  const cleanCurrent =
    current.protocol + "//" + current.host + current.pathname;

  return cleanTarget === cleanCurrent;
}

function parsePath(path: string) {
  const hashIndex = path.indexOf("#");
  const queryIndex = path.indexOf("?");
  const hasQuery = queryIndex > -1 && (hashIndex < 0 || queryIndex < hashIndex);

  if (hasQuery || hashIndex > -1) {
    return {
      pathname: path.substring(0, hasQuery ? queryIndex : hashIndex),
      query: hasQuery
        ? path.substring(queryIndex, hashIndex > -1 ? hashIndex : undefined)
        : "",
      hash: hashIndex > -1 ? path.slice(hashIndex) : ""
    };
  }

  return { pathname: path, query: "", hash: "" };
}

function addPathPrefix(path: string, prefix?: string) {
  if (!path.startsWith("/") || !prefix) {
    return path;
  }

  const { pathname, query, hash } = parsePath(path);
  return `${prefix}${pathname}${query}${hash}`;
}

export function getAnchorProperty<
  T extends HTMLAnchorElement | SVGAElement,
  K extends keyof T,
  P extends T[K]
>(a: T, key: K): P extends SVGAnimatedString ? string : P {
  if (typeof key === "string" && key === "data-disable-nprogress") {
    const dataKey = key.substring(5) as keyof DOMStringMap;
    return a.dataset[dataKey] as any;
  }

  const prop = a[key];

  if (prop instanceof SVGAnimatedString) {
    const value = prop.baseVal as unknown;

    if (key === "href") {
      return addPathPrefix(value as string, location.origin) as any;
    }

    return value as any;
  }

  return prop as any;
}

// Encoding
export function encodeUrl(url: string): string {
  return btoa(encodeURIComponent(url));
}

// Decoding
export function decodeUrl(encodedUrl: string): string {
  return atob(decodeURIComponent(encodedUrl));
}

import { ButtonCustomTedd } from "@/components/atoms/button";
import { ButtonProps } from "@/components/atoms/button/ButtonCustom";
import { BlurImage, EmptyImage } from "@/public/images";
import clsx from "clsx";
import Image, { ImageProps } from "next/image";
import Link from "next/link";
import React from "react";
import CardDescription from "./CardDescription";

export function checkThumbanil(thumbnail: string) {
  return !thumbnail ||
    thumbnail === '""' ||
    thumbnail === '"' ||
    thumbnail === "" ||
    thumbnail == "-" ||
    !(thumbnail && (thumbnail?.startsWith("http") || thumbnail?.startsWith("/")))
    ? EmptyImage
    : thumbnail;
}

export function isThumbanil(thumbnail: string) {
  return !thumbnail ||
    thumbnail === '""' ||
    thumbnail === '"' ||
    thumbnail === "" ||
    thumbnail == "-" ||
    !(thumbnail && (thumbnail?.startsWith("http") || thumbnail?.startsWith("/")))
    ? false
    : true;
}

interface Props {
  className?: string;
  a: DataProduct;
  noNameTag?: boolean;
  imgProps?: Omit<ImageProps, "src" | "layout" | "placeholder" | "blurDataURL">;
  btnProps?: Omit<ButtonProps, "price" | "title">;
}

export default function Product(props: Props) {
  const { a, ...rest } = props;

  return (
    <div className="group flex flex-col gap-2">
      <Link
        id={`${a?.name_product?.replaceAll(" ", "-")}-${a?.product_id}`}
        href={`/product/${a.name_product.replaceAll(" ", "+")}-${a._id}`}
        className={clsx(
          "flex w-full aspect-[4/5] bg-primaryGON-100 overflow-hidden @md:rounded-[30px] rounded-[20px] relative transition-all",
          isThumbanil(a.thumbnail)
            ? "text-start items-start place-content-start"
            : "text-center items-center place-content-center"
        )}
      >
        {rest.imgProps?.fill ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <Image
            src={checkThumbanil(a.thumbnail)}
            className="bg-cover w-auto h-auto group-hover:scale-125 transition-all duration-500"
            blurDataURL={BlurImage?.blurDataURL}
            placeholder="blur"
            quality="85"
            {...rest.imgProps}
          />
        ) : (
          <Image
            src={checkThumbanil(a.thumbnail)}
            alt={`nibras-${a.thumbnail}`}
            className="bg-cover w-auto h-auto group-hover:scale-125 transition-all duration-500"
            blurDataURL={BlurImage?.blurDataURL}
            placeholder="blur"
            height={540}
            width={540}
            quality="85"
            {...rest.imgProps}
          />
        )}
        <ButtonCustomTedd
          price={a.price_max}
          title={a.name_product}
          label={{ width: "half" }}
          {...rest.btnProps}
        />
      </Link>
      <CardDescription a={a} />
    </div>
  );
}
